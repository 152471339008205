// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-js": () => import("./../../../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-adelphatech-collision-toronto-js": () => import("./../../../src/pages/adelphatech-collision-toronto.js" /* webpackChunkName: "component---src-pages-adelphatech-collision-toronto-js" */),
  "component---src-pages-adelphatech-lescalator-js": () => import("./../../../src/pages/adelphatech-lescalator.js" /* webpackChunkName: "component---src-pages-adelphatech-lescalator-js" */),
  "component---src-pages-careers-js": () => import("./../../../src/pages/careers.js" /* webpackChunkName: "component---src-pages-careers-js" */),
  "component---src-pages-case-studies-js": () => import("./../../../src/pages/case-studies.js" /* webpackChunkName: "component---src-pages-case-studies-js" */),
  "component---src-pages-cbc-details-js": () => import("./../../../src/pages/cbc-details.js" /* webpackChunkName: "component---src-pages-cbc-details-js" */),
  "component---src-pages-compass-group-fed-app-js": () => import("./../../../src/pages/compass-group-fed-app.js" /* webpackChunkName: "component---src-pages-compass-group-fed-app-js" */),
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-deliveryease-platform-js": () => import("./../../../src/pages/deliveryease-platform.js" /* webpackChunkName: "component---src-pages-deliveryease-platform-js" */),
  "component---src-pages-empower-details-js": () => import("./../../../src/pages/empower-details.js" /* webpackChunkName: "component---src-pages-empower-details-js" */),
  "component---src-pages-handyman-js": () => import("./../../../src/pages/handyman.js" /* webpackChunkName: "component---src-pages-handyman-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-instasize-details-js": () => import("./../../../src/pages/instasize-details.js" /* webpackChunkName: "component---src-pages-instasize-details-js" */),
  "component---src-pages-international-school-service-web-portal-js": () => import("./../../../src/pages/international-school-service-web-portal.js" /* webpackChunkName: "component---src-pages-international-school-service-web-portal-js" */),
  "component---src-pages-labs-js": () => import("./../../../src/pages/labs.js" /* webpackChunkName: "component---src-pages-labs-js" */),
  "component---src-pages-linkrocket-js": () => import("./../../../src/pages/linkrocket.js" /* webpackChunkName: "component---src-pages-linkrocket-js" */),
  "component---src-pages-punchlist-js": () => import("./../../../src/pages/punchlist.js" /* webpackChunkName: "component---src-pages-punchlist-js" */),
  "component---src-pages-salesprophet-js": () => import("./../../../src/pages/salesprophet.js" /* webpackChunkName: "component---src-pages-salesprophet-js" */),
  "component---src-pages-services-js": () => import("./../../../src/pages/services.js" /* webpackChunkName: "component---src-pages-services-js" */),
  "component---src-pages-webhelp-multilingual-hiring-portal-js": () => import("./../../../src/pages/webhelp-multilingual-hiring-portal.js" /* webpackChunkName: "component---src-pages-webhelp-multilingual-hiring-portal-js" */)
}

